<template>
  <v-container fluid>
    <PageHeaderSlot>
      <template slot="top-right-slot">
        <v-btn
          text
          depressed
          class="primary ml-2"
          @click="handleUpgradeStudent()"
          :loading="$store.getters.isLoading"
          v-if="canUpgrade"
        >
          <v-icon size="20" class="mr-3">{{ icon.mdiArrowUpCircleOutline }}</v-icon>
          {{ $t('upgradeStudent') }}
        </v-btn>
        <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateStudent' }" v-if="canCreate">
          <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
          {{ $t('createStudent') }}
        </v-btn>
      </template>
    </PageHeaderSlot>

    <Datatable
      :isLoading="tableLoading"
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page="tablePage"
      :pageLimit="tableLimit"
      :itemTotal="tableItemTotal"
      enableSearch
      @search-clicked="searchKeyword($event)"
      @search-input="tableSearchKey = $event"
      enableClearAll
      @all-search-cleared="clearSearch()"
      @refresh-clicked="refreshData()"
      enableRefresh
      @options-update="onTableOptionsChange($event)"
      enableExport
      @export-clicked="exportData()"
      actionViewRouteLink="StudentDetails"
      enableAdvancedSearch
      @advanced-search-clicked="openAdvancedSearchDialog($event)"
      enableMultiTypeSearch
      :searchTypeOptions="searchTypeOptions"
      :searchType.sync="tableSearchType"
      :initSearchKeyword="querySearch"
      :enableImport="canCreate"
      @import-clicked="importData($event)"
    ></Datatable>

    <ConfirmDialog ref="confirmUpgradeDialog" title="message.confirmUpgrade" :isDeleteDialog="false">{{
      $t('message.confirmUpgradeMsg')
    }}</ConfirmDialog>

    <DialogLayout
      :openDialog="advancedDialogOpen"
      :maxWidth="910"
      title="table.advanceSearch"
      enableScroll
      confirmBtnText="table.search"
      @close="handleAdvancedSearchDialogClose($event)"
    >
      <v-row class="ma-0">
        <v-col cols="12" sm="6">
          <FormSelect
            label="grade"
            :fieldValue.sync="filter.grade"
            :options="$gradeOptions"
            placeholder="selectGrade"
            hideDetails
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <FormInput
            label="studentSchool"
            :fieldValue.sync="filter.school"
            placeholder="studentSchool"
            hideDetails
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <FormSelect
            label="memberLevel"
            :fieldValue.sync="filter.memberLevel"
            :options="$memberOptions"
            placeholder="selectMemberLevel"
            hideDetails
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <FormDatePicker
            label="regYearMonth"
            placeholder="selectRegYearMonth"
            hideDetails
            :dateValue.sync="filter.regYearMonth"
            isYearMonthOnly
            dense
            disabledActionButtons
          />
        </v-col>
      </v-row>
    </DialogLayout>

    <ConfirmDialog ref="confirmToImportDialog" title="message.confirmToImport" :isDeleteDialog="false">{{
      $t('message.confirmToImportMsg')
    }}</ConfirmDialog>
  </v-container>
</template>

<script>
import { mdiArrowUpCircleOutline, mdiPlusCircleOutline } from '@mdi/js'

import Datatable from '@/components/Datatable.vue'
import ExcelMixin from '@/mixins/ExcelMixin.vue'

import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { cannotAccessList } from '@/assets/cannotAccessList'

import DialogLayout from '@/components/layout/DialogLayout.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import FormInput from '@/components/formField/FormInput.vue'

export default {
  name: 'StudentList',
  mixins: [ExcelMixin],
  components: {
    Datatable,
    ConfirmDialog,
    FormSelect,
    FormDatePicker,
    DialogLayout,
    FormInput,
  },
  computed: {
    query() {
      return this.$route.query
    },
    querySearch() {
      return this.$route.query.search || ''
    },
  },
  data: vm => ({
    tableLoading: false,
    tableData: [],
    tablePage: 1,
    tableLimit: 10,
    tableItemTotal: 0,
    tableSearchKey: '',
    tableSearchType: 'name',
    advancedDialogOpen: false,

    exportLoading: false,

    /* 進階搜尋 */
    filter: {
      grade: '',
      school: '',
      memberLevel: '',
      regYearMonth: '',
    },
    filterSelected: {
      // 禁咗搜尋之後save
      grade: '',
      school: '',
      memberLevel: '',
      regYearMonth: '',
    },

    // --- static data
    icon: {
      mdiArrowUpCircleOutline,
      mdiPlusCircleOutline,
    },
    tableHeaders: [
      { value: 'student_code', text: vm.$t('studentCode') },
      { value: 'grade', text: vm.$t('grade') },
      { value: 'student_name', text: vm.$t('name') },
      { value: 'student_school_name', text: vm.$t('studentSchool') },
      { value: 'phone', text: vm.$t('phone') },
      { value: 'reg_date', text: vm.$t('regDate') },
      { value: 'actionView', text: '', align: 'end' },
    ],
    searchTypeOptions: [
      { value: 'name', text: vm.$t('studentName') },
      { value: 'student_code', text: vm.$t('studentCode') },
      { value: 'phone', text: vm.$t('phone') },
    ],

    canUpgrade: true,
    canCreate: true,
  }),

  methods: {
    async getPayload() {
      let payload = {
        filter_limit: this.tableLimit,
        filter_page: this.tablePage - 1,
      }

      const selectedSchool = await this.getUserSelectedSchool()
      if (this.$validate.DataValid(selectedSchool)) {
        payload.center_id = selectedSchool
      }

      const filterItems = []
      if (this.$validate.DataValid(this.tableSearchKey)) {
        const keyword = this.tableSearchKey.trim()

        if (this.$validate.DataValid(this.tableSearchType)) {
          if (this.tableSearchType === 'id') {
            if (this.$validate.isValidId(keyword)) {
              const id = keyword.substring(1)
              filterItems.push({ key: 'id', value: parseInt(id) })
            } else if (this.$validate.regexNumber(keyword)) {
              filterItems.push({ key: 'id', value: parseInt(keyword) })
            }
          } else {
            filterItems.push({ key: this.tableSearchType, value: keyword })
          }
        } else {
          payload.search = keyword
        }
      }

      if (this.$validate.DataValid(this.filterSelected.grade)) {
        filterItems.push({ key: 'grade', value: this.filterSelected.grade })
      }

      if (this.$validate.DataValid(this.filterSelected.school)) {
        filterItems.push({ key: 'school', value: this.filterSelected.school })
      }

      if (this.$validate.DataValid(this.filterSelected.memberLevel)) {
        filterItems.push({ key: 'level', value: this.filterSelected.memberLevel })
      }

      if (this.$validate.DataValid(this.filterSelected.regYearMonth)) {
        filterItems.push({ key: 'create_date', value: this.filterSelected.regYearMonth })
      }
      if (filterItems.length) {
        payload.filter_item = filterItems
      }

      return payload
    },
    async getStudentData(regenUrl = true) {
      if (regenUrl === true) {
        this.regenerateURL()
      }

      this.tableLoading = true
      try {
        const payload = await this.getPayload()

        const { data, total } = await this.$Fetcher.GetStudents(payload)
        this.tableData = data.map(el => {
          return {
            id: el.id,
            student_code: el.student_code,
            grade: el.grade,
            student_name: el.student_name,
            student_school_name: el.student_school_name,
            phone: el.phone,
            reg_date: el.reg_date,
          }
        })

        this.tableItemTotal = total
      } catch {
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },
    searchKeyword(keyword) {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchKey = keyword.trim()
      this.getStudentData()
    },
    clearSearch() {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchKey = ''
      this.tableSearchType = this.searchTypeOptions[0].value
      this.filterSelected.grade = ''
      this.filterSelected.school = ''
      this.filterSelected.memberLevel = ''
      this.filterSelected.regYearMonth = ''
      this.getStudentData()
    },
    refreshData() {
      this.tableLimit = 10
      this.tablePage = 1
      this.tableItemTotal = 0
      this.getStudentData()
    },
    onTableOptionsChange(options) {
      if (options.itemsPerPage !== this.tableLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      this.tableLimit = options.itemsPerPage
      this.getStudentData()
    },

    // ========= 進階搜尋 =========
    openAdvancedSearchDialog(allow) {
      if (this.tableLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })

        return
      }

      if (allow === true) {
        this.filter.grade = this.filterSelected.grade
        this.filter.school = this.filterSelected.school
        this.filter.memberLevel = this.filterSelected.memberLevel
        this.filter.regYearMonth = this.filterSelected.regYearMonth
        this.advancedDialogOpen = true
      }
    },
    handleAdvancedSearchDialogClose(action) {
      if (action === true) {
        this.filterSelected.grade = this.filter.grade
        this.filterSelected.school = this.filter.school
        this.filterSelected.memberLevel = this.filter.memberLevel
        this.filterSelected.regYearMonth = this.filter.regYearMonth
        this.tablePage = 1
        this.tableItemTotal = 0
        this.getStudentData()
      }
      this.advancedDialogOpen = false
    },

    // ========= 學生升級 =========
    async handleUpgradeStudent() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }
      const confirm = await this.$refs.confirmUpgradeDialog.show()

      if (confirm) {
        this.$store.dispatch('setLoading', true)
        try {
          await this.$Fetcher.UpgradeStudents()
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.upgradeSuccess',
            type: 'success',
            refresh: true,
          })
        } catch (errMsg) {
          this.setDialogMessage({
            title: 'message.upgradeFail',
            message: errMsg,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    // ========= 匯出報表 =========
    async exportData() {
      if (this.exportLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.exportLoading = true

      try {
        const header = {
          student_code: this.$t('studentCode'),
          grade: this.$t('grade'),
          student_name: this.$t('name'),
          student_school_name: this.$t('studentSchool'),
          parent_phone: this.$t('phone'),
          reg_date: this.$t('regDate'),
          status: this.$t('enrollStatus'),
        }
        let payload = await this.getPayload()
        delete payload['filter_limit']
        delete payload['filter_page']

        const { data } = await this.$Fetcher.GetStudents(payload)
        const exportData = data.map(el => {
          return {
            student_code: el.student_code,
            grade: el.grade ? this.$grade[el.grade] : '',
            student_name: el.student_name,
            student_school_name: el.student_school_name,
            parent_phone: el.phone,
            reg_date: el.reg_date,
            status: el.status === 'active' ? this.$t('studentStatus.active') : this.$t('studentStatus.quit'),
          }
        })

        this.exportExcel(
          header,
          exportData,
          this.$t('menu.student'),
          `student_${this.$formatter.formatDate(new Date()).replace(/-/g, '')}.xlsx`,
        )
      } catch (err) {
        this.$common.error(err)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.exportFail',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      } finally {
        this.exportLoading = false
      }
    },

    async importData(file) {
      const confirm = await this.$refs.confirmToImportDialog.show()
      if (confirm) {
        try {
          const data = await this.importExcel(file)
          const required = {
            student_name: true,
            phone: true,
            username: true,
            password: true,
          }
          if (data.length) {
            data.forEach(item => {
              Object.keys(required).forEach(key => {
                if (!item[key]) {
                  required[key] = false
                }
              })
            })

            const invalid = []
            Object.keys(required).forEach(key => {
              if (!required[key]) {
                invalid.push(key)
              }
            })

            if (invalid.length) {
              throw `${this.$t('message.required')} : ${invalid}`
            } else {
              let school_id = -1;
              const selectedSchool = await this.getUserSelectedSchool()
              if (this.$validate.DataValid(selectedSchool)) {
                school_id = selectedSchool
              }

              const students = [];
              data.forEach(item => {
                const obj = {
                  school_id: -1,
                  student_name: item.student_name,
                  student_eng_name: item.student_name_en || "",
                  phone: (item.phone).toString(),
                  username: item.username,
                  password: (item.password).toString(),
                  contact_person_1_name: item.parent_name || "",
                  contact_person_1_phone: item.parent_phone ? (item.parent_phone).toString() : "",
                  gender: item.gender || "",
                  birth_date: "",
                  student_school_name: item.school || "",
                  address: item.address || "",
                  remark: item.remark || "",
                }

                if (item.birthday) {
                  if (this.$validate.regexDateStr(item.birthday)) {
                    obj.birth_date = item.birthday;
                  } else {
                    const old = item.birthday - 1;
                    const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
                    const time = new Date(1900, 0, old, 0, 0, t);
                    obj.birth_date = this.$formatter.formatDate(time);
                  }
                }

                if (item.center_id && this.$validate.regexNumber((item.center_id).trim())) {
                  obj.school_id = parseInt((item.center_id).trim());
                } else {
                  obj.school_id = school_id;
                }

                students.push(obj);
              })

              const { result, data: returnData } = await this.$Fetcher.ImportStudent(students);
              if (result === 'success') {
                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: 'message.importSuccess',
                  type: 'success',
                  refresh: false,
                })
              } else {
                this.setDialogMessage({
                  title: 'message.importStudentFail',
                  message: returnData.join('<br>'),
                  isError: true,
                  returnLink: null,
                })
                this.setShowDialog(true)
              }
              this.clearSearch()
            }
          }
        } catch (err) {
          this.setDialogMessage({
            title: 'message.importFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    getQuery() {
      if (Object.keys(this.query).length > 0) {
        if (this.$validate.DataValid(this.query.searchType)) {
          this.tableSearchType = this.query.searchType
        }

        if (this.$validate.DataValid(this.querySearch)) {
          this.tableSearchKey = this.querySearch
        }

        if (this.$validate.DataValid(this.query.page) && this.$validate.regexNumber(this.query.page)) {
          this.tablePage = parseInt(this.query.page)
        }

        if (this.$validate.DataValid(this.query.limit) && this.$validate.regexNumber(this.query.limit)) {
          this.tableLimit = parseInt(this.query.limit)
        }

        if (this.$validate.DataValid(this.query.grade)) {
          this.filterSelected.grade = this.query.grade
        }
        if (this.$validate.DataValid(this.query.school)) {
          this.filterSelected.school = this.query.school
        }
        if (this.$validate.DataValid(this.query.level)) {
          this.filterSelected.memberLevel = this.query.level
        }
        if (this.$validate.DataValid(this.query.date)) {
          this.filterSelected.regYearMonth = this.query.date
        }
      }
    },

    regenerateURL() {
      const q = {
        searchType: this.tableSearchType,
        search: this.tableSearchKey.trim(),
        page: this.tableLimit !== 10 || this.tablePage !== 1 ? this.tablePage : null,
        limit: this.tableLimit !== 10 || this.tablePage !== 1 ? this.tableLimit : null,
        grade: this.filterSelected.grade,
        school: this.filterSelected.school,
        level: this.filterSelected.memberLevel,
        date: this.filterSelected.regYearMonth,
      }
      const newQuery = this.$common.diffQuery(q, this.query)
      if (newQuery) {
        this.$router.replace({ query: newQuery })
      }
    },

    async handleSiteLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: StudentList')
      const check = await this.checkUserData()
      if (check) {
        this.getQuery()
        await this.getStudentData(false)
      }
    },
  },

  destroyed() {
    window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  mounted() {
    this.tableLoading = true
    window.addEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  created() {
    const userType = this.getUserType()
    if (!userType) {
      this.canCreate = false
      this.canUpgrade = false
    } else {
      if (
        cannotAccessList['CreateStudent'] &&
        cannotAccessList['CreateStudent'].length &&
        cannotAccessList['CreateStudent'].includes(userType)
      ) {
        this.canCreate = false
      }

      if (
        cannotAccessList['upgradeStudent'] &&
        cannotAccessList['upgradeStudent'].length &&
        cannotAccessList['upgradeStudent'].includes(userType)
      ) {
        this.canUpgrade = false
      }
    }
  },
}
</script>
